import * as React from "react"
import { Nav, Sidebar, Cursor } from '../components/main'

const Main = ({loadSequence, children}) => {

  const pageWrapper = "page-container",
        pageInner = "page-inner";

  return (
    <div className={pageWrapper}>
      <div id="top"></div>
      <Cursor loadSequence={loadSequence} />
      <Sidebar loadSequence={loadSequence} />
      <Nav loadSequence={loadSequence} />
      <div className={pageInner}>
        {children}
      </div>
    </div>
  );
}

export default Main
