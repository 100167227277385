import * as React from "react"
import { useEffect, useState } from "react"
import classNames from "classnames";
import {isMobile} from 'react-device-detect';

const Cursor = ({ loadSequence }) => {

  const [position, setPosition] = useState({ x:0, y: 0 });
  const [hidden, setHidden] = useState(false);
  const [focused, setFocused] = useState(false);

  useEffect(() => {

    loadSequence.fromTo( document.querySelector('.cursor'), { opacity: 0 }, { opacity: 1, duration: 1 }, 2 );

    if (isMobile) {
        setHidden(true);
    }

    addEventListeners();
    return () => removeEventListeners();
  }, []);

  const addEventListeners = () => {
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseenter", onMouseEnter);
    document.addEventListener("mouseleave", onMouseLeave);

    document.querySelectorAll('a').forEach((item, i) => {
      item.addEventListener("mouseenter", onLinkOver);
      item.addEventListener("mouseleave", onLinkOut);
    });
  }

  const removeEventListeners = () => {
    document.removeEventListener("mousemove", onMouseMove);
    document.removeEventListener("mouseenter", onMouseEnter);
    document.removeEventListener("mouseleave", onMouseLeave);

    document.querySelectorAll('a').forEach((item, i) => {
      item.removeEventListener("mouseenter", onLinkOver);
      item.removeEventListener("mouseleave", onLinkOut);
    });
  }

  const onLinkOver = () => {
    setFocused(true);
  }

  const onLinkOut = () => {
    setFocused(false);
  }

  const onMouseMove = (e) => {
    setPosition({x: e.clientX, y: e.clientY});
  };

  const onMouseLeave = () => {
    setHidden(true);
  };

  const onMouseEnter = () => {
    setHidden(false);
  };

  const cursorClasses = classNames(
    'cursor',
    {
      'cursor--hidden': hidden
    },
    {
      'cursor--focused': focused
    }
  );

  return <div
            className={cursorClasses}
            style={{
              left: `${position.x}px`,
              top: `${position.y}px`
            }} />

}

export default Cursor
