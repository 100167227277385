import * as React from "react"
import { RoleBlock } from '../components/resume'

const Resume = () => {

  const Experience = [
      {
        start: 2021,
        role: 'Creative Director & CTO',
        company: 'Fund.me',
        location: 'Irvine, California',
        details: {
          summary: 'Currently leading branding, marketing, and visual collteral efforts, as well as spearheading development of the tech platform for a hard mony real estate startup',
          highlights: [
            'Refined and redeveloped the company brand, including the launch of a new company website',
            'Developed the company tech platform and API via a pure Javascript stack (Next.js/React/Node) & MongoDB',
            'Ideated and executed initial marketing, lead nurturing, and customer experience efforts to gain brand equity and establish best practice systems'
          ],
          clients: []
        }
      },
    {
      start: 2018,
      end: 2021,
      role: 'Director of Design & Development',
      company: 'Arise Design + Brand',
      location: 'San Diego, California',
      details: {
        summary: 'Led all visual design and development efforts for the small-to-mid sized branding and creative agency.',
        highlights: [
          'Modernized service delivery across all company services, implementing new technologies, best practices, and strategic initiatives which increased revenue year-over-year, including the largest single-year growth in the midst of a global pandemic.',
          'Flexibly scaled team capabilities through management of internal resources and a network of vetted outsource partners.'
        ],
        clients: [ 'WD40', 'BVAccel', 'Gouvernement de la République française (French Goverment)', 'Alliant International University', 'COX Communications' ]
      }
    },
    {
      start: 2011,
      end: 2018,
      role: 'Freelance Creative Director & Full-Stack Developer',
      location: 'Boston, Massachusetts<br />Belo Horizonte, Minas Gerais, Brazil<br />San Diego, California',
      details: {
        summary: 'Full-service creative consultant providing a wide range of digital services to small, medium, and large business clients.',
        highlights: [
          'Planned, designed, and launched 30+ websites, countless marketing campaigns and print media collateral pieces, brand identities, and more.',
          'Excelled in both team member and team leadership capacities, executing both full-stack independently and in specialized, single-discipline roles.',
          'Provided strategic and visual support on investor presentations leading to the acquisition of over $25MM across several funding rounds.'
        ],
        clients: [ 'Cynosure (Hologic)', 'Ameego', 'Forrester Research', 'netBlazr', 'Mass Innovation Nights' ]
      }
    }
  ];

  return (
    <div id="resume" className="resume">
    {
      Experience.map(function(role, index){
        return ( <RoleBlock
                  start={role.start}
                  end={role.end}
                  role={role.role}
                  company={role.company}
                  location={role.location}
                  details={role.details}
                  index={index} /> );
      })
    }
    </div>
  );
}

export default Resume
