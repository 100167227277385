import * as React from "react"
import { useEffect, useState } from "react"
import { gsap } from "gsap"
import { ScrollToPlugin, ScrollTrigger, CSSRulePlugin } from "gsap/all"
import AnchorLink from 'react-anchor-link-smooth-scroll'

const Nav = ({ loadSequence }) => {

  const navItems = [
    { text: 'Work', link: '#project-0' },
    { text: 'Skills', link: '#skills' },
    { text: 'Experience', link: '#resume' },
    { text: 'About', link: '#bio' },
    { text: 'Contact', link: '#footer' }
  ];

  const [ offsetHeight, setOffsetHeight ] = useState(0);
  const offsetModifier = 1.25;

  const year = new Date().getFullYear();

  useEffect(() => {

    setOffsetHeight(document.querySelector('.nav').offsetHeight);

    loadSequence.set( document.querySelector('.nav'), { autoAlpha: 1 }, 0 );
    loadSequence.fromTo( document.querySelector('.navbar'), { y: -50, opacity: 0 }, { y: 0, opacity: 1, duration: 2,  ease: "elastic.out(1, .5)" }, .75);

  }, [ loadSequence, offsetHeight ]);

  return (
    <div id="nav" className="nav">
      <div className="navbar">
        <nav className="navbar__nav">
          { navItems && navItems.map(function(navItem, index){
            return <AnchorLink href={navItem.link} offset={offsetHeight * offsetModifier}>
              {navItem.text}
            </AnchorLink>
          }) }
        </nav>
        <div className="navbar__copyright">&copy; {year}</div>
      </div>
    </div>
  );
}

export default Nav
