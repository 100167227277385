import * as React from "react"
import { useState, useEffect } from "react"
import { gsap } from "gsap"
import { ScrollToPlugin, ScrollTrigger, CSSRulePlugin } from "gsap/all"
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import SimpleReactLightbox from 'simple-react-lightbox';
import { SRLWrapper } from "simple-react-lightbox";

const Brief = ({project}) => {

  const lightBoxOptions = {
      settings: {
    overlayColor: "rgba(0, 0, 0, .9)"
  },
  buttons: {
    backgroundColor: "#000000",
    iconColor: "rgba(255,255,255, 1)",
    showDownloadButton: false,
    showFullscreenButton: false,
    showAutoplayButton: false,
    showThumbnailsButton: false,
    size: "32px"
},
caption: {
    captionColor: "#FFFFFF",
    captionFontFamily: "BM Ultra, sans-serif",
    captionFontWeight: "400",
},
thumbnails: {
    showThumbnails: false
}
  };

  const BriefRef = React.createRef();

  function get_text(el) {
    var ret = "";
    var length = el.childNodes.length;
    for(var i = 0; i < length; i++) {
        var node = el.childNodes[i];
        if(node.nodeType != 8) {
            ret += node.nodeType != 1 ? node.nodeValue : get_text(node);
        }
    }
    return ret;
}

  useEffect(() => {

    var words = get_text(BriefRef.current.querySelector('.brief-details'));
    var count = words.split(' ').length;

    BriefRef.current.querySelector('.rt').innerText = Math.ceil(count / 200);

  }, []);

  return (

    <div class="project__brief brief" ref={BriefRef}>
      <a class="close">Close Brief</a>
      <div class="brief__fader"></div>
      <div class="brief__body">
        <h2 class="brief__section">01 &mdash; Project Summary</h2>
        <h3 class="brief__name">{project.name}</h3>
        <div class="brief-table">
          <div class="brief-table__row">
            <div class="brief-table__header">Year</div>
            <div class="brief-table__content">{project.brief.year}</div>
          </div>
          <div class="brief-table__row">
            <div class="brief-table__header">Client</div>
            <div class="brief-table__content">{project.brief.client}</div>
          </div>
          <div class="brief-table__row">
            <div class="brief-table__header">Responsibilities</div>
            <div class="brief-table__content">{project.responsibilities.join(', ')}</div>
          </div>
          <div class="brief-table__row">
            <div class="brief-table__header">Stack</div>
            <div class="brief-table__content">{project.stack.join(', ')}</div>
          </div>
          <div class="brief-table__row">
            <div class="brief-table__header">Brief Read Time</div>
            <div class="brief-table__content"><span class="rt">0</span> minutes</div>
          </div>
        </div>
        <h2 class="brief__section">02 &mdash; Project Details</h2>
        <div class="brief-details">
          {
            project.brief.body.map(function(item, index){

              if ( item.type === 'heading' ) {
                return <h3 className="brief-details__heading">{item.content}</h3>
              }
              else return <div className="brief-details__body">{ReactHtmlParser(item.content)}</div>
            })
          }
        </div>
      </div>
      <div class="brief__image-scroll">Scroll</div>
      <div class="brief__images">
        <SimpleReactLightbox>
            <SRLWrapper options={lightBoxOptions}>
            { project.brief.images.map(function(image, index){
                return <img src={image.image} alt={image.alt} />
            }) }
            </SRLWrapper>
        </SimpleReactLightbox>
      </div>
    </div>

  );

}

export default Brief
