import * as React from 'react'
import { useState, useEffect } from "react"
import { gsap } from "gsap"
import { ScrollToPlugin, ScrollTrigger, CSSRulePlugin } from "gsap/all"

const ListBlock = ({ category, items, index }) => {

  if ( category, items ) {
    return (
      <div className="skillSet__block" key={index}>
        <div className="skillSet__category">{ category }</div>
        <ul className="skillSet__items">
          { items.map(function(item, index){
            return ( <li className="skillSet__item" key={index}>{ item }</li> )
          }) }
        </ul>
      </div>
    );
  } else {
    return null;
  }
}

export default ListBlock
