import * as React from "react"
import { useState, useEffect } from "react"
import { gsap } from "gsap"
import { ScrollToPlugin, ScrollTrigger, CSSRulePlugin } from "gsap/all"

const Bio = () => {

  const giantText = "Biography";
  const locationsHeading = "Places I've Lived";

  const locations = [
    { city: 'Boston', active: false },
    { city: 'Belo Horizonte', active: false },
    { city: 'San Diego', active: false },
    { city: 'Orange County', active: true }
  ];

  const questions = [
    {
      q: "Trivia",
      a: "I'm a musician and can play over 20 different instruments. I originally attended the Berklee College of Music, intending to study Film Scoring. While there, I once jammed with John Mayer."
    },
    {
      q: "My purpose is...",
      a: "To use my creative talent in order to create the maximum amount of positive change in the world that I can in the time I have."
    },
    {
      q: "When I'm not working, I'm usually...",
      a: "Playing one of my instruments, in the gym or running through the city, sharing a laugh with friends, documenting the beauty of the world around me with my phone, or watching soccer."
    },
    {
      q: "I'm currently listening to...",
      a: "Daniel Caesar, Warez, Bonobo, Tycho, Sylvan Esso, The Black Keys, Hopsin, Kanye West, BØRNS, Norah Jones, Jamie XX, SZA, H.E.R., Rodrigo Alarcon, MEMBA, Damien Rice, John Mayer, Josean Log"
    }
  ];

  useEffect(() => {

    const giantTextTimeline = gsap.timeline({
      scrollTrigger: {
        scrub: true,
        trigger: document.querySelector('.bio'),
        start: 'top bottom',
        toggleActions: 'play none none reverse'
      }
    });

    giantTextTimeline.fromTo(
      document.querySelector('.bio__giantText'),
      { right: 0 }, { right: '50%', duration: 1 }, 0
    );

    const bioTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: document.querySelector('.bio'),
        start: 'center bottom',
        toggleActions: 'play none none none'
      }
    });

    var locationsAnimationStart = .5,
        locationsAnimationFlashDur = .75;

    document.querySelectorAll('.locations__loc').forEach((item, i) => {
      if ( i + 1 < locations.length ) {
        bioTimeline.fromTo( item, { opacity: 0, y: 25 }, { opacity: 1, y: 0, duration: locationsAnimationFlashDur }, locationsAnimationStart);
        bioTimeline.to( item, { opacity: .25, duration: locationsAnimationFlashDur }, locationsAnimationStart + locationsAnimationFlashDur );
        locationsAnimationStart = locationsAnimationStart + locationsAnimationFlashDur;
      } else {
        bioTimeline.fromTo( item, { opacity: 0 }, { opacity: 1, duration: locationsAnimationFlashDur }, locationsAnimationStart);
      }
    });

    bioTimeline.fromTo( document.querySelectorAll('.bio__heading'), { opacity: 0, x: -50 }, { x: 0, opacity: 1, duration: 1.5, ease: "elastic.out(1, .5)", stagger: { each: 0.2, from: "start" } }, 0 );
    bioTimeline.fromTo( document.querySelectorAll('.questions p'), { opacity: 0, x: -50 }, { x: 0, opacity: 1, duration: 1.5, ease: "elastic.out(1, .75)", stagger: { each: 0.25, from: "start" } }, .75 );

  }, []);

  return (
    <div id="bio" className="bio">
      <h2 className="bio__giantText">{ giantText }</h2>
      <div className="bio__locations locations">
        <div className="bio__heading">{ locationsHeading }</div>
        { locations && locations.map(function(location, index){
          return <div className={`locations__loc`} key={index}>{ location.city }</div>
        })}
      </div>
      <div className="bio__questions questions">
        { questions && questions.map(function(question, index){
          return(
            <div className="questions__block" key={index}>
              <div className="bio__heading">{ question.q }</div>
              <p>{ question.a }</p>
            </div>
          )
        }) }
      </div>
    </div>
  );
}

export default Bio
