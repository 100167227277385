import * as React from 'react'
import { useState, useEffect } from "react"
import { gsap } from "gsap"
import { ScrollToPlugin, ScrollTrigger, CSSRulePlugin } from "gsap/all"

const Sidebar = () => {

  useEffect(() => {

    const skillsSidebarTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: document.querySelector('.skills'),
        start: "top bottom-=240",
        ease: "power2",
        toggleActions: "play none none none"
      }
    });

    skillsSidebarTimeline.fromTo( document.querySelector('.skills__heading').children, { opacity: 0 }, { opacity: 1, stagger: .1,  duration: 1 }, 0 );

  }, []);

  const Heading = () => {
    return (
      <h2 className="skills__heading">
        <span className="wt-regular">Skills</span><br />
        <span className="wt-medium">Tools &amp;</span><br />
        <span className="wt-demibold">Technologies</span>
      </h2>
    )
  }

  return (
    <div className="skills__sidebar">
      <Heading />
    </div>
  );
}

export default Sidebar
