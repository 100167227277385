import * as React from "react"
import { useEffect } from "react"
import { gsap } from "gsap"
import { ScrollToPlugin, ScrollTrigger, CSSRulePlugin } from "gsap/all"
import AnchorLink from 'react-anchor-link-smooth-scroll'

const Sidebar = ({loadSequence}) => {

  const quote = {
    text: "To create is to live twice",
    author: "Albert Camus"
  };

  const QuoteRef = React.createRef();

  useEffect(() => {

    loadSequence.set( document.querySelector('.sidebar'), { autoAlpha: 1 }, 0 );
    loadSequence.fromTo( CSSRulePlugin.getRule(`.sidebar::after`), { cssRule: { height: 0 } }, { cssRule: { height: '100%' }, duration: .75 }, 0);
    loadSequence.fromTo( CSSRulePlugin.getRule(`.sidebar .quote::after`), { cssRule: { height: 0 } }, { cssRule: { height: '100%' }, duration: 2, ease: "elastic.out(1, .5)" }, .75);
    loadSequence.fromTo( QuoteRef.current.children, { x: -50, opacity: 0 }, { x: 0, opacity: 1, duration: 2,  ease: "elastic.out(1, .5)" }, .75);

  }, []);

  return (
    <div id="sidebar" className="sidebar">
      <div className="sidebar__quote quote" ref={QuoteRef}>
        <div className="quote__text">&ldquo;{ quote.text }&rdquo;</div>
        <div className="quote__author">&mdash; { quote.author }</div>
      </div>
    </div>
  );
}

export default Sidebar
