import * as React from "react"
import { useState, useEffect } from "react"
import { Footer, Hero, Main, Bio, Project, Resume, Skills } from '../layouts'
import { gsap } from "gsap"
import { ScrollToPlugin, ScrollTrigger, CSSRulePlugin } from "gsap/all"
import { Helmet } from "react-helmet"

import '../styles/styles.scss'

const IndexPage = () => {

  gsap.registerPlugin(CSSRulePlugin, ScrollToPlugin, ScrollTrigger);
  const [loadSequence, setLoadSequence] = useState( gsap.timeline({}) );

  useEffect(() => {

    loadSequence.set( document.querySelectorAll('.project'), { autoAlpha: 1 }, 0 );
    loadSequence.fromTo( document.querySelectorAll('.project')[0].querySelector('.project__visual'), { opacity: 0 }, { opacity: 1 , duration: 1 }, 2);
    loadSequence.fromTo( document.querySelectorAll('.project')[0].querySelector('.project__visual'), { y: 50 }, { y:0, duration: 2, ease: "elastic.out(1, .5)" }, 2);

  }, []);

  return (
    <Main loadSequence={loadSequence}>
      <Helmet>
        <title>Alex Edwards</title>
        <meta name="description" content="Alex Edwards is a Creative Director, Full-Stack Developer, and Entrepreneur based in San Diego, California, open to new opportunities." />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Hero loadSequence={loadSequence}/>
      <Project id={0} />
      <Skills />
      <Project id={1} />
      <Resume />
      <Project id={2} />
      <Bio />
      <Footer />
    </Main>
  )
}

export default IndexPage
