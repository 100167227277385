import * as React from 'react'
import { useState, useEffect } from "react"
import { gsap } from "gsap"
import { ScrollToPlugin, ScrollTrigger, CSSRulePlugin } from "gsap/all"
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser'

const RoleBlock = ({ start, end, role, company, location, details, index }) => {

  const clientsHeading  = "Notable Clients",
        presentText     = "Present",
        clientDelimiter = ", ";

  const DateRef = React.createRef(),
        MainRef = React.createRef(),
        TitleRef = React.createRef(),
        DetailsRef = React.createRef(),
        ClientsRef = React.createRef(),
        RoleRef = React.createRef(),
        CompanyRef = React.createRef(),
        LocationRef = React.createRef();

  function addRoleTimeline( role, index ) {

    const roleTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: role,
        start: 'center bottom',
        ease: 'power2',
        toggleActions: 'play none none none'
      }
    });

    const date = role.querySelector('.role__date'),
          main = role.querySelector('.role__main'),
          details = role.querySelector('.role__details'),
          clients = role.querySelector('.clients');

    roleTimeline.fromTo( date, { x: -50, opacity: 0 }, { x: 0, opacity: 1, duration: 1.5, ease: "elastic.out(1, .5)" }, 0 );
    roleTimeline.fromTo( main, { x: -50, opacity: 0 }, { x: 0, opacity: 1, duration: 1.5, ease: "elastic.out(1, .5)" }, 0.1 );
    roleTimeline.fromTo( details, { x: -50, opacity: 0 }, { x: 0, opacity: 1, duration: 1.5, ease: "elastic.out(1, .5)" }, 0.2 );

  }

  useEffect(() => {
    document.querySelectorAll('.role').forEach(addRoleTimeline);
  }, []);

  return (
    <div className="resume__role role" key={index}>
      <div className="role__date">{ start } &mdash; { end || presentText }</div>
      <div className="role__main">
        <h3 className="role__title">{ role }</h3>
        { company && <div className="role__company">{ company }</div> }
        { location && <div className="role__location">{ ReactHtmlParser(location) }</div> }
      </div>
      <div className="role__details">
        { details.summary && <p className="role__summary">{ details.summary }</p> }
        { details.highlights && details.highlights.map(function(item, index){
          return <p key={index}>{ item }</p>
        }) }
        <footer className="role__clients clients">
          <h4 className="clients__heading">{clientsHeading}</h4>
          <ul className="clients__list">
            { details.clients.map(function(client, index){
              return <li className="clients__client" key={index}>{ client }{ index < ( details.clients.length - 1 ) && clientDelimiter }</li>
            }) }
          </ul>
        </footer>
      </div>
    </div>
  );

}

export default RoleBlock
