import * as React from "react"
import { useState, useEffect } from "react"
import { gsap } from "gsap"
import { ScrollToPlugin, ScrollTrigger, CSSRulePlugin } from "gsap/all"
import { Brief } from '../components/project'
import PSMAFeatureImg from '../projects/personal-safety/feature.jpg';
import PSMAImage2Img from '../projects/personal-safety/image1.jpg';
import FurnaceFeatureImg from '../projects/furnace/feature.png';
import FurnaceImage2Img from '../projects/furnace/image1.png';
import FurnaceImage3Img from '../projects/furnace/image2.png';
import AriseFeatureImg from '../projects/arise/featured.jpg';
import AriseSBEImg from '../projects/arise/sbe.jpg';
import AriseSDImg from '../projects/arise/sd.jpg';
import AriseSDMEImg from '../projects/arise/sdme.jpg';

const Project = ({id}) => {

  const projects = [

    {
      name: 'Personal Safety Mobile App',
      responsibilities: [
        'Creative Direction',
        'Branding & Visual Identity',
        'User Interface Design',
        'User Experience Design'
      ],
      stack: [
        'Sketch',
        'Illustrator',
        'Photoshop',
        'React Native'
      ],
      background: 'linear-gradient(to bottom right, #5374E1, #A72A50)',
      featuredImage: PSMAFeatureImg,
      brief: {
        images: [
          {
              image: PSMAFeatureImg,
              alt: 'PSMA Screens'
          },
          {
              image: PSMAImage2Img,
              alt: 'Home Screen'
          }
        ],
        year: 2020,
        client: 'Project has been whitelabeled for presentational purposes.',
        body: [
          {
            type: 'heading',
            content: 'Overview'
          },
          {
            type: 'paragraph',
            content: "<p>When you find yourself in an unfamiliar, uncomfortable, or perhaps even dangerous situation &mdash; time is of the essence. In those scenarios, our phones become both our biggest ally and, in many cases, our biggest obstacle to assistance as well.</p> <p>The sequence of events that must take place in those situations suddenly becomes arduous and daunting, even if you're lucky enough to still be in possession of your phone. You need to fumble around for the phone, unlock it with either facial recognition, pattern or code, select the appropriate app, choose the appropriate recipient, and then alert them to your danger by call or typing a text. A sequence like that requires focus in even the calmest of situations, nervermind in a situation where you're attempting to escape danger. Or worse - imagine you're trapped, and the person trapping you has gained possession of your phone. What do you do now?</p><p><strong>Personal Safety Mobile App(PSMA)</strong> is the first voice-activated safety app. Triggered by customizable phrases, PSMA immediately begins to record your surroundings, sends an emergency text message to designated contacts, and generates a link where those contacts can track your location in real-time. No matter the situation, help is always just a few words away.</p>"
          },
          {
            type: 'heading',
            content: 'Challenge'
          },
          {
            type: 'paragraph',
            content: "<p>I was brought in by PSMA to lead the entirety of their visual identity &mdash; from initial stategic discussions surrounding brand positioning, through to the execution of the brand identity, app interface, and company website. For simplicity and clarity, this brief will focus on the mobile app, given its importance and the challenges it presented.</p><p>While PSMA was the first of its kind in regards to voice activation, we were entering a crowded competitive landscape populated by several established companies that had developed apps geared toward parenting and general family security. Each of these companies had several years of existence under their belt, and in many cases had amassed a sizeable customer base at the time we were developing PSMA.</p><p>The core functionality of our app itself created its own UX challenges. Manual trigger of the SOS, in the event the voice activation did not trigger properly, was an absolute necessity for the safety of our customers, and we needed to ensure the path to that action was as resistance-free as possible. GPS tracking screens needed to be clear and informative. Any time-added during any part of the user's activity flow could be the difference between safety and harm, which added increased urgency and weight to every UX decision we made.</p>"
          },
          {
            type: 'heading',
            content: 'Solution'
          },
          {
            type: 'paragraph',
            content: "<p>Performing a brand-focused competitive analysis gave us a blueprint for how to execute the interface and overall experience of the PSMA app. The nature of our competitors' apps had forced them to shift most of their resources post-launch to customer support &mdash; a pivot which meant that aesthetics and usability were constantly being pushed to the backburner. Many had interfaces that looked dated, and key interactions based on usability conventions of years past. We saw this as a major opportunity during market entry, and the design and usability of the PSMA app became a cornerstone of our differentiation.</p><p>Colors, color gradients and brand design elements were subsequently chosen to enhance the feelings of trust and safety we sought for the app to create. We incorporated comprehensive white space to minimize clutter and distraction, and straightforward navigation supporting the core user behaviors. Contrast was used to draw attention to important information and actions.</p><p>Extensive usability testing guided us in establishing the structure of the interface to minimize resistance during the performance of key user actions. The aforementioned manual SOS button could be switched to the natural thumb hover position of either dominant hand. The GPS tracking screen was augmented with buttons which provided one-touch paths for emergency phone calling, generating directions to the user's location, or viewing vital medical information.</p><p>Each aspect of the design and experience of using the app was meticulously crafted with the safety and security of the end-user in mind.</p>"
          },
          {
            type: 'heading',
            content: 'Results'
          },
          {
            type: 'paragraph',
            content: "<p>PSMA mobile app entered a public beta for approximately 3-4 months before it was acquired by and folded into a larger competitor. I assisted in executing the asset handoff and onboarding the new company's internal team to our efforts surrounding design and usability. The core features and functionality have since been rolled into the competitor's platform.</p>"
          }
        ]
      }
    },

    {
      name: 'Furnace.io',
      responsibilities: [
        'Branding & Visual Identity',
        'User Interface Design',
        'User Experience Design'
      ],
      stack: [
        'Sketch',
        'Figma'
      ],
      background: 'linear-gradient(to bottom right, rgb(235,93,18), rgb(191,26,62))',
      featuredImage: FurnaceFeatureImg,
      brief: {
        images: [
            {
                image: FurnaceFeatureImg,
                alt: 'Stack Overview'
            },
            {
                image: FurnaceImage2Img,
                alt: 'Component Overview w/ Open Drawer'
            },
            {
                image: FurnaceImage3Img,
                alt: 'Flow Builder'
            }
        ],
        year: 2020,
        client: 'Furnace',
        body: [
          /* {
            type: 'paragraph',
            content: '"I always enjoy working with Alex. His ability to think strategically and holistically, from the core tenets of our brand through the design phase and how it will be implemented in code, maximizes the efficiency of the project and, invariably, delivers to us an end-product of exceptional quality." &mdash; Valdis Barrett, VP of Product, Furnace'
        }, */
          {
            type: 'heading',
            content: 'Challenge'
          },
          {
            type: 'paragraph',
            content: "<p>Turning vast amounts of technical information and complex behaviors into an interface that is not only intuitive, but actually enjoyable to use, is the endless struggle of those of us tasked with developing these kinds of interfaces and experiences. Furnace has those challenges in spades.</p><p>Furnace is a flexible streaming data pipeline solution that allows you to spend less time designing and managing infrastructure, leaving you more time to focus on doing great things with your data. It is a platform agnostic, serverless solution that allows you to build and manage your pipeline visually using building blocks, called constructs, that perform the pipeline\'s key functions. Once set up, you can track and monitor the status, performance, and manage billing for your pipelines through the interface as well.</p><p>The variety and vast amount of information needing to be consumed via the different behaviors possible through the platform, along with the concurrent need to provide a modern interface consistent with the Furnace brand, made each interface and UX decision that much more important as we progressed through the design stage of realizing the platform.</p>"
        },
        {
          type: 'heading',
          content: 'Solution'
        },
        {
          type: 'paragraph',
          content: "<p>A heavy focus was placed on keeping the interface modular for clarity and digestibility. The foundations of the interface revolve largely around hierarchial \'card\' and \'drawer\' UI elements.</p><p>White and gray space was used generously to ensure that the interface never felt overwhelming or cluttered, even in the presence of a abundance of technical information and actions available to the user.</p><p>A rounded geometric sans-serif font was used throughout the interface to keep it approachable. It is the only typeface used throughout the project, to further reduce the amount of visual distraction to the user.</p><p>A minimal brand color palette was used for the core aspects of the interface. In places where a significant amount of options or information presents itself, we leveraged vivid primary colors to aid the user in making distinctions between the various sections.</p><p>The user can build their pipeline using a friendly, drag-and-drop experience. This further augments the \'human touch\' of the app and allows the user to bargain with the interface in a more engaging and focused way.</p>"
        },
        {
          type: 'heading',
          content: 'Results'
        },
        {
          type: 'paragraph',
          content: "<p>Furnace currently exists as a command-line interface, built on Node. The Furnace platform is expected to launch in 2021.</p>"
        }
      ]
      }
    },

    {
      name: 'Arise Design + Brand: Web Projects',
      responsibilities: [
        'Creative Direction',
        'Branding & Visual Identity',
        'Website Design',
        'Responsive Design',
        'Front-End Development',
        'Back-End Development',
        'Strategy',
        'Presentation Design',
        'Design Leadership'
      ],
      stack: [
        'Sketch',
        'Illustrator',
        'Photoshop',
        'HTML',
        'CSS/SASS',
        'Prismic',
        'React',
        'Gatsby',
        'PHP',
        'SLIM Framework',
        'Wordpress',
        'Webflow'
      ],
      background: 'linear-gradient(to bottom right, #0291AD, #0D171A)',
      featuredImage: AriseFeatureImg,
      brief: {
        images: [
            {
                image: AriseFeatureImg,
                alt: 'www.arisedesigngroup.com'
            },
            {
                image: AriseSBEImg,
                alt: 'www.sarabrownevents.com'
            },
            {
                image: AriseSDImg,
                alt: 'www.scatenadaniels.com'
            },
            {
                image: AriseSDMEImg,
                alt: 'www.sdmeetings.com'
            }
        ],
        year: '2018-2021',
        client: 'Various',
        body: [
          {
            type: 'heading',
            content: 'Company Overview'
          },
          {
            type: 'paragraph',
            content: "<p>Arise Design + Brand is a small-to-mid sized branding and digital agency dedicated to creating positive impact in the world through branding and design. We seek to work exclusively with purpose-driven companies who share a similar mindset surrounding making a net-positive impact on the world. I joined Arise Design + Brand as Director of Design &amp; Development in 2018. In the years since, I have spearheaded transformative growth across all facets of the organization - helping it to not only survive, but thrive through the COVID-19 pandemic, and positioning it to be a force in Southern California for years to come.</p>"
        },
        {
            type: 'heading',
            content: 'Growth Catalyst'
        },
        {
            type: 'paragraph',
            content: '<p>Key highlights from my time at Arise:</p><p><ul><li> Increase in company capabilities and strategic expertise led to an increase in average total contract value by 100% year-over-year.</li><li>Increase in company revenue year-over-year, including during the COVID-19 pandemic.</li><li>Led the agency rebranding effort and re-designed the agency website.</li><li>Executed more than 30 web projects for a variety of clients across a variety of industries.</li><li>Expanded the company network of strategic partnerships and freelance talent.</li><li>Positioned the company for the future success by introducing and incorporating modern technologies into its project stack.</p>'
        },
        {
            type: 'heading',
            content: 'Projects'
        },
        {
            type: 'paragraph',
            content: '<p>The images in this brief represent a selection of web projects executed in my role at Arise Design + Brand. Code examples and links to specific projects can be made available upon request. URLs can be viewed by clicking the image and opening its lightbox view. The plurality of the projects shown were built using a SLIM Framework (PHP) or Gatsby (JS + GraphQL) back-end, fed by the Prismic headless CMS.</p>'
        }
        ]
      }
    },

    {
      name: 'Example 4',
      responsibilities: [
        'Creative Direction',
        'Branding & Visual Identity',
        'User Interface Design',
        'User Experience Design'
      ],
      stack: [
        'Sketch',
        'Illustrator',
        'Photoshop (Visual)',
        'React Native (Code)'
      ],
      background: '#497CB7',
      featuredImage: 'https://images.unsplash.com/photo-1587934924167-a3a04e138374?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80',
      brief: {
        images: [],
        year: 2020,
        client: 'Project has been whitelabeled for presentational purposes.',
        body: [
          {
            type: 'heading',
            content: 'Challenge'
          },
          {
            type: 'paragraph',
            content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
          }
        ]
      }
    },

    {
      name: 'Example 5',
      responsibilities: [
        'Creative Direction',
        'Branding & Visual Identity',
        'User Interface Design',
        'User Experience Design'
      ],
      stack: [
        'Sketch',
        'Illustrator',
        'Photoshop (Visual)',
        'React Native (Code)'
      ],
      background: '#222222',
      featuredImage: 'https://images.unsplash.com/photo-1545401791-ab4c6050dd4f?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80',
      brief: {
        images: [],
        year: 2020,
        client: 'Project has been whitelabeled for presentational purposes.',
        body: [
          {
            type: 'heading',
            content: 'Challenge'
          },
          {
            type: 'paragraph',
            content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
          }
        ]
      }
    }
  ];

  const [ briefIsOpen, setBriefIsOpen ] = useState(false);

  const briefTimelines = [];

  function addProjectTimeline( project, index ) {

    var staggerItems = [];
    var summaryItems = project.querySelector('.project-summary__content').children;

    for ( var i = 0; i < summaryItems.length; i++ ) {
      staggerItems.push( summaryItems[i] );
    }


    if ( index > 0 ) {
      const projectTimeline = gsap.timeline({
        scrollTrigger: {
          trigger: project,
          start: 'center bottom',
          ease: 'power2',
          toggleActions: 'play none none none'
        }
      });

      projectTimeline.fromTo( staggerItems, { opacity: 0, x: -50 }, { opacity: 1, x: 0, duration: 1.25, ease: "elastic.out(1, .5)", stagger: { each: 0.1, from: "start" } }, 0);
      projectTimeline.fromTo( project.querySelector('.project-summary__id'), { opacity: 0, x: -25 }, { opacity: 1, x: 0, duration: 1.25, ease: "elastic.out(1, .5)", stagger: { each: 0.1, from: "start" } }, .75);
      projectTimeline.fromTo( project.querySelector( '.project__wipe' ), { x: "-100%" }, { x: "100%", duration: 1, ease: "none" }, .25 );
      projectTimeline.to( project.querySelector( '.project__featuredImage' ), { opacity: 1, duration: .05, ease: "none" }, .75 );
      projectTimeline.to( project.querySelector( '.project__featuredImage img' ), { scale: 1.15, duration: 5, ease: "back.out(1.7)" }, 0 );

    } else {

      const projectTimeline = gsap.timeline({
        scrollTrigger: {
          trigger: project,
          start: 'center bottom',
          ease: 'power2',
          toggleActions: 'play none none none'
        }
      });
      projectTimeline.fromTo( project.querySelector( '.project__wipe' ), { x: "-100%" }, { x: "100%", duration: 1, ease: "none" }, .25 );
      projectTimeline.to( project.querySelector( '.project__featuredImage' ), { opacity: 1, duration: .1, ease: "none" }, .75 );
      projectTimeline.to( project.querySelector( '.project__featuredImage img' ), { scale: 1.15, duration: 5, ease: "back.out(1.7)" }, 0 );

    }

    briefTimelines[index] = gsap.timeline({ paused: true });
    briefTimelines[index].fromTo( project.querySelector( '.brief' ), { x: "100%" }, { x: 0, duration: .75 }, 0);
    briefTimelines[index].fromTo( project.querySelectorAll('.brief__images img'), { opacity: 0 }, { opacity: 1, duration: .75, stagger: .25 }, .75 );
    briefTimelines[index].fromTo( project.querySelectorAll('.brief__image-scroll'), { opacity: 0 }, { opacity: 1, duration: .5 }, 1.5 );
    briefTimelines[index].fromTo( project.querySelector( '.brief__body' ), { opacity: 0, x: -50 }, { opacity: 1, x: 0, duration: 1, ease: "back.out(1.7)" }, 1 );
    briefTimelines[index].fromTo( project.querySelector( '.close' ), { opacity: 0, x: 50 }, { opacity: 1, x: 0, duration: 1, ease: "back.out(1.7)" }, 1.25 );

    project.querySelector('.project-summary__footer a').addEventListener("click", () => {
      briefTimelines[index].play();
      console.log('Opened.');
    });

    project.querySelector('.close').addEventListener("click", () => {
      briefTimelines[index].reverse();
      console.log('Closed.');
    });

  }

  useEffect(() => {

    document.querySelectorAll('.project').forEach(addProjectTimeline);

  }, []);

  if ( id in projects ) {

    return (
      <div className={`project ${ id % 2 === 0 ? 'even' : 'odd' } ${ id === 0 ? 'first' : '' }`} id={`project-${id}`}>
        <Brief project={projects[id]} />
        <div className="project__summary project-summary">
          <div className="project-summary__inner">
            <small className="project-summary__id">{ (id + 1).toString().padStart(2, "0") }</small>
            <div className="project-summary__content">
              <h3 className="project-summary__title">{ projects[id].name }</h3>
              <div className="divider"></div>
              <h4 className="project-summary__heading">Responsibilities</h4>
              <p>{ projects[id].responsibilities && projects[id].responsibilities.map(function(res, index){
                if ( index < projects[id].responsibilities.length - 1 ) return res + ', ';
                else return res;
              }) }</p>
              <h4 className="project-summary__heading">Stack</h4>
              <p>{ projects[id].stack && projects[id].stack.map(function(tech, index){
                if ( index < projects[id].stack.length - 1 ) return tech + ', ';
                else return tech;
              }) }</p>
              <footer className="project-summary__footer">
                <a title={`View Project Brief for ${ projects[id].name }`}>View Brief</a>
              </footer>
            </div>
          </div>
        </div>
        <div className="project__visual" style={{
          background: projects[id].background
        }}>
          <div className="project__wipe"></div>
          { projects[id].featuredImage &&
            <div className="project__featuredImage">
              <img src={ projects[id].featuredImage } alt={ projects[id].name } />
            </div>
          }
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default Project
