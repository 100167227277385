import * as React from "react"
import { useState, useEffect } from "react"
import { gsap } from "gsap"
import { ScrollToPlugin, ScrollTrigger, CSSRulePlugin } from "gsap/all"
import { ListBlock, Sidebar as SkillsSidebar } from '../components/skills'

const Skills = () => {

  const SkillSet = [
    {
      category: 'Languages & Frameworks',
      items: [
        'HTML',
        'CSS/LESS/SCSS',
        'Javascript',
        'jQuery',
        'React',
        'PHP',
        'Headless CMS',
        'React Native',
        'Gatsby',
        'NEXTjs',
        'Greensock.js',
        'ScrollMagic',
        'd3.js',
        'Wordpress',
        'Git',
        'Shopify',
        'CodeIgniter',
        'Laravel',
        'MongoDB'
      ]
    },
    {
      category: 'Tools & Software',
      items: [
        'Adobe Photoshop',
        'Adobe Illustrator',
        'Adobe InDesign',
        'Adobe XD',
        'Adobe Lightroom',
        'Adobe Premiere',
        'Adobe Premiere Rush',
        'Sketch',
        'Figma'
      ]
    },
    {
      category: 'Skills & Knowledge',
      items: [
        'Creative Direction',
        'Graphic Design',
        'Responsive Web Design',
        'User Interface Design',
        'User Experience Design',
        'Print Media Design',
        'Presentation Design',
        'Content Strategy',
        'SEO',
        'Design Systems',
        'Design Leadership',
        'Identity Systems',
        'Copywriting',
        'Front-End Development',
        'Back-End Development',
        'Branding & Identity',
        'Strategic Planning',
        'Business Process',
        'Business Model Design',
        'Marketing Strategy',
        'Content Development',
        'Campaign Ideation',
        'Leadership',
        'Competitive Analysis',
        'Market Research'
      ]
    },
    {
      category: 'Languages',
      items: [
        'English (Native)',
        'Portuguese (Professional)',
        'Spanish (Intermediate)',
        'Italian (Elementary)'
      ]
    }
  ];

  function addSkillBlockTimeline( block, index ) {

    const skillBlockTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: block,
        start: 'center bottom',
        ease: 'power2',
        toggleActions: 'play none none none'
      }
    });

    skillBlockTimeline.fromTo( [
      block.querySelector('.skillSet__category'),
      block.querySelector('.skillSet__items')
    ], { opacity: 0, x: -50 },
        { opacity: 1, x: 0, duration: 1.5, ease: "elastic.out(1, .5)", stagger: .5 }, 0);

    skillBlockTimeline.fromTo( block.querySelectorAll('.skillSet__item'),
  { opacity: 0 }, { opacity: 1, duration: .5, stagger: { grid: "auto", from: "random", each: .1 } }, .75 );

  }

  useEffect(() => {
    document.querySelectorAll('.skillSet__block').forEach(addSkillBlockTimeline);
  }, []);

  return (
    <div id="skills" className="skills">
      <SkillsSidebar />
      <div className="skills__list skillSet">
      {
        SkillSet.map(function(skill, index){
          return ( <ListBlock
                    category={skill.category}
                    items={skill.items}
                    index={index} /> );
        })
      }
      </div>
    </div>
  );

}

export default Skills
