import * as React from "react"
import { useEffect, useState } from "react"
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser'
import { gsap } from "gsap"
import { ScrollToPlugin, ScrollTrigger, CSSRulePlugin } from "gsap/all"
import AnchorLink from 'react-anchor-link-smooth-scroll'

const Footer = () => {

  const callToAction = "Let's talk.";
  const builtWithText = "Built with";
  const builtWith = [
    { name: 'React', link: 'https://www.reactjs.org' },
    { name: 'Gatsby', link: 'https://www.gatsbyjs.com/' },
    { name: 'Greensock', link: 'https://greensock.com/'}
  ];

  const [ navHeight, setNavHeight ] = useState(0);

  useEffect(() => {

    setNavHeight(document.querySelector('.nav').offsetHeight);

    const footerTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: document.querySelector('.page-footer'),
        start: "center bottom",
        ease: 'power2',
        toggleActions: "play none none none"
      }
    });

    footerTimeline.fromTo( document.querySelector('.page-footer__contact'), { opacity: 0 }, { opacity: 1, duration: 1 }, 0 );

    const builtWithTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: document.querySelector('.page-footer'),
        start: "center bottom",
        ease: "power2",
        toggleActions: "play none none none"
      }
    });

    builtWithTimeline.fromTo( document.querySelector('.page-footer__builtWith'), { x: 50, opacity: 0 }, { x: 0, opacity: 1, ease: "elastic.out(1, .5)", duration: 1.5 }, 0);

    const btttTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: document.querySelector('.page-footer'),
        start: "center bottom",
        ease: "power2",
        toggleActions: "play none none reverse"
      }
    });

    btttTimeline.set( document.querySelector('.back-to-top'), { autoAlpha: 1 }, 0 );
    btttTimeline.fromTo( document.querySelector('.back-to-top'), { yPercent: 100 }, { yPercent: 0, duration: .35 }, 0);

  }, [ navHeight ]);

  return (
    <footer className="page-footer" id="footer">
      <div className="page-footer__contact"><a href="mailto: hello@alexedwards.is" title={ callToAction }>{ callToAction }</a></div>
      <div className="page-footer__builtWith">{builtWithText} { builtWith && builtWith.map(function(software, index){ return ( index < builtWith.length - 1 ? ReactHtmlParser(`<a href=${software.link} title=${software.name}>${software.name}</a>, `) : <a href={software.link} title={software.name}>{software.name}</a> ) }) }</div>
      <div className="back-to-top"><AnchorLink href="#top" offset={ navHeight }>Back to Top</AnchorLink></div>
    </footer>
  );
}

export default Footer
