import * as React from "react"
import { useEffect } from "react"
import { gsap } from "gsap"
import { ScrollToPlugin, ScrollTrigger, CSSRulePlugin } from "gsap/all"

const Hero = ({loadSequence}) => {

  const greeting    = "Welcome. Glad you're here.",
        headline    = "I'm Alex Edwards, a Brand & Marketing Executive and Full-Stack Developer based in San Diego, California.";

  const GreetingRef = React.createRef(),
        HeadingRef =  React.createRef();

  useEffect(() => {

    loadSequence.set( document.querySelector('.hero'), { autoAlpha: 1 }, 0 );
    loadSequence.fromTo( [ GreetingRef.current, HeadingRef.current ], { x: -50, opacity: 0 }, { x: 0, opacity: 1 , duration: 2, stagger: .25, ease: "elastic.out(1, .5)" }, 1.25);

  }, []);

  return (
    <div className="hero">
      <div className="hero__greeting" ref={GreetingRef}>{ greeting }</div>
      <h1 className="hero__headline" ref={HeadingRef}>{ headline }</h1>
    </div>
  );
}

export default Hero
